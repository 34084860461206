:root {
  --background: #f5f5f4;
  --maincolor: #ff9dc3;
  --titlecolor: #1f1f1f;
  --darkbackground: #1f1f1f;
  --darkmaincolor: #ffdbe9;
  --darktitlecolor: #f5f5f4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.bodydiv,
.bodydivdark {
  background-color: var(--background);
  height: 100vh;
  transition: ease-in-out 0.3s;
}

.bodydivdark {
  background-color: var(--darkbackground);
}

a {
  color: inherit;
  text-decoration: none;
}

.btn,
.btndark {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--maincolor);
  font-size: 20px;

  padding-top: 5px;
  color: black;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.btndark {
  background-color: var(--darktitlecolor);
  transition: ease-in-out 0.3s;
}

.container,
.containerdark {
  background-color: var(--background);
  max-width: 400px;
  margin: auto;
  text-align: center;
  padding: 20px;
  transition: ease-in-out 0.3s;
}

.containerdark {
  background-color: var(--darkbackground);
}

.buttondiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: ease-in-out 0.3s;
}

.profilepic {
  object-fit: cover;
  border-radius: 100%;
  width: 105px;
  height: 105px;
}

h1 {
  color: var(--titlecolor);
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 6px;
}

p {
  color: var(--titlecolor);
  font-size: 14.5px;
  max-width: 315px;
  margin: auto;
  margin-bottom: 30px;
  line-height: 18px;
}

.dark {
  color: var(--darktitlecolor);
}

.darktext {
  color: var(--darktitlecolor);
}

.boxdiv,
.boxdivdark {
  margin: 10px 10px;
  padding: 5px;
  border: 2px solid var(--maincolor);
  border-radius: 5px;
  color: var(--maincolor);
  font-size: 12px;
}

.boxdivdark {
  background-color: var(--darkbackground);
  border: 2px solid var(--darkmaincolor);
}

.boxdiv:hover {
  background-color: var(--maincolor);
  color: var(--titlecolor);
}

.boxdivdark:hover {
  background-color: var(--darkmaincolor);
  color: var(--darkbackground);
}
